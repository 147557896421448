import React from 'react';
import { graphql, withPrefix } from 'gatsby';
import { Helmet } from 'react-helmet';

import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from '../containers/layout';
import SEO from '../components/blog/seo';

export const query = graphql`
  query MiluimQuery {
    pageLabels_home: sanityPage(pageName: { eq: "Home" }) {
      labels {
        labelKey
        labelValue {
          heb
          en
          es
        }
      }
    }
    pageLabels_long_term_info: sanityPage(pageName: { eq: "long_term_info" }) {
      labels {
        labelKey
        labelValue {
          heb
          en
          es
        }
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
  }
`;

const page = (props: any) => {
  const { data, errors, location } = props;
  const site = (data || {}).site;
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <Helmet>
        <script src={withPrefix('/scripts/gtag.js')}></script>
      </Helmet>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />

      <iframe
        src='https://docs.google.com/forms/d/e/1FAIpQLSfod8nK-H4iPRb9V9XnJ0CYibXgOh6qc6wOoN648PbvcWsxJQ/viewform?embedded=true'
        width='640'
        height='382'
        frameBorder='0'
        marginHeight={0}
        marginWidth={0}
      >
        Loading…
      </iframe>
    </Layout>
  );
};
export default page;
